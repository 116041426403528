import { theme } from "@product/scmp-sdk";
import { type FunctionComponent } from "react";
import { graphql, useFragment } from "react-relay";

import { AdSlot } from "scmp-app/components/advertisement/ad-slots/ad-slot";
import { TargetingPaidType } from "scmp-app/components/advertisement/ad-slots/types";
import { ContentItemMagazinesPostMagLead } from "scmp-app/components/content/content-item-render/variants/post-magazine-lead";
import { AdContainer } from "scmp-app/components/section/section-post-magazine/styles";
import type { leadStoryPostMagazineContent$key } from "scmp-app/queries/__generated__/leadStoryPostMagazineContent.graphql";

import { Container } from "./styles";

export type Props = {
  adZone?: string;
  className?: string;
  reference: leadStoryPostMagazineContent$key;
  sectionId: string;
};
export const PostMagazineLeadStory: FunctionComponent<Props> = ({
  adZone = "default",
  className,
  reference: reference_,
  sectionId,
}) => {
  const data = useFragment(
    graphql`
      fragment leadStoryPostMagazineContent on Content {
        ...postMagazineLeadContentItemContent
      }
    `,
    reference_,
  );

  if (!data) return null;

  return (
    <Container className={className}>
      <ContentItemMagazinesPostMagLead reference={data} topicLinkVariant={{ type: "main" }}>
        <AdContainer>
          <AdSlot
            adUnit="d_lrec1"
            breakpoint={theme.breakpoints.up("tablet")}
            sizes={[[300, 250], "fluid"]}
            targeting={{
              paid: TargetingPaidType.Free,
              scsid: [sectionId],
            }}
            withBackground
            withLabel
            zone={adZone}
          />
          <AdSlot
            adUnit="m_banner1"
            breakpoint={theme.breakpoints.only("mobile")}
            sizes={[[300, 50], [320, 50], [300, 100], [320, 100], [300, 250], "fluid"]}
            targeting={{
              paid: TargetingPaidType.Free,
              scsid: [sectionId],
            }}
            withBackground
            withLabel
            zone={adZone}
          />
        </AdContainer>
      </ContentItemMagazinesPostMagLead>
    </Container>
  );
};

PostMagazineLeadStory.displayName = "PostMagazineHeader";
