import { section as sectionData } from "shared/data/section";

import type { SectionWidget } from "./types";

export const getIncludeSectionFeatureMap = (entityId: string) => {
  const widgets = getSectionWidgets(entityId);
  return {
    includeAsiaDiscoveryWidget: widgets.includes("asia-discovery"),
    includeChinaScienceDiscoveryWidget: widgets.includes("china-science-discovery"),
    includeComment: widgets.includes("comment"),
    includeFocusArticle: widgets.includes("focus-article"),
    includeMostPopular: widgets.includes("most-popular"),
    includeMultimedia: widgets.includes("multimedia"),
    includeOpenQuestionsSeriesDiscoveryWidget: widgets.includes("open-questions-series-discovery"),
    includePlus: widgets.includes("plus"),
    includeThisWeekInAsiaDiscovery: widgets.includes("this-week-in-asia-discovery"),
    includeTrendingTopics: widgets.includes("trending-topics"),
  };
};

export const getSectionWidgets = (entityId: string): SectionWidget[] => {
  switch (entityId) {
    case sectionData.asia.entityId:
      return [
        "this-week-in-asia-discovery",
        "comment",
        "recommended-for-you",
        "multimedia",
        "trending-topics",
        "most-popular",
      ];
    case sectionData.business.entityId:
    case sectionData.economy.entityId:
    case sectionData.hongKong.entityId:
      return [
        "plus",
        "comment",
        "recommended-for-you",
        "multimedia",
        "trending-topics",
        "most-popular",
      ];
    case sectionData.china.entityId:
      return [
        "china-science-discovery",
        "comment",
        "recommended-for-you",
        "multimedia",
        "trending-topics",
        "most-popular",
      ];
    case sectionData.chinaFutureTech.entityId:
      return [
        "focus-article",
        "plus",
        "comment",
        "recommended-for-you",
        "multimedia",
        "trending-topics",
        "most-popular",
      ];
    case sectionData.comment.entityId:
    case sectionData.kPop.entityId:
    case sectionData.sport.entityId:
    case sectionData.tech.entityId:
      return ["recommended-for-you", "comment", "multimedia", "trending-topics"];
    case sectionData.lifestyle.entityId:
      return ["post-magazine", "comment", "recommended-for-you", "multimedia", "trending-topics"];
    case sectionData.news.peopleAndCulture.entityId:
      return ["post-magazine", "recommended-for-you", "comment", "multimedia", "trending-topics"];
    case sectionData.olympic.entityId:
      return ["recommended-for-you", "multimedia", "most-popular"];
    case sectionData.thisWeekInAsia.entityId:
      return [
        "asia-discovery",
        "comment",
        "recommended-for-you",
        "multimedia",
        "trending-topics",
        "most-popular",
      ];
    case sectionData.us.entityId:
    case sectionData.us.usElections.entityId:
      return ["focus-article", "comment", "recommended-for-you", "multimedia", "trending-topics"];
    case sectionData.world.entityId:
      return [
        "open-questions-series-discovery",
        "comment",
        "recommended-for-you",
        "multimedia",
        "trending-topics",
        "most-popular",
      ];
    default:
      return ["comment", "recommended-for-you", "multimedia", "trending-topics", "most-popular"];
  }
};
